@import "bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import "react-toastify/dist/ReactToastify.css";
@import "bootstrap-daterangepicker/daterangepicker.css";
@import "react-datepicker/dist/react-datepicker.css";


*{
    font-family: sans-serif;
	--red : #d71a21;
    --green : #1aa260;
}

.spinner {
    animation: spin infinite 2s linear;

    /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
               -webkit-appearance: none;
                margin: 0;
        }
 
        input[type=number] {
            -moz-appearance: textfield;
        }

        

.hr-sect {
	display: flex;
	flex-basis: 100%;
	align-items: center;
	color: rgba(0, 0, 0, 0.35);
	margin: 8px 0px;
    color: black;
}
.hr-sect::before,
.hr-sect::after {
	content: "";
	flex-grow: 1;
	background: rgba(0, 0, 0, 0.35);
	height: 1px;
	font-size: 0px;
	line-height: 0px;
	margin: 0px 8px;
    color: black;
}




.bg-grad-primary{
	background-color: linear-gradient(to right, #d71a21 0%, #b31217  51%, #e52d27  100%) !important
}

.text-grad-primary{
	color: linear-gradient(to right, #d71a21 0%, #b31217  51%, #e52d27  100%) !important
}

.bg-primary{
	background-color: var(--red) !important
}

.text-primary{
	color: var(--red) !important
}

.text-secondary{
	color: var(--green) !important
}

.btn-primary{
	background-color: var(--red) !important;
	border-color: var(--red) !important
}

.bg-success{
	background-color: var(--green) !important
}

.text-success{
	color: var(--green) !important
}

.btn-success{
	background-color: var(--green) !important;
	border-color: var(--green) !important
}

.loaderCont{
    width: 100%;
    display: contents;
    .loaderDiv{
        height: 104%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .overlayDiv{
        height: 104%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #80808030;
        opacity: 0.7;
    }
    .loadingComp{
        position: absolute;
        top:260px;
        font-size: 14px;
        font-weight: 600;
        color: black;
    }
}

         
.btn-grad-primary {
    background-image: linear-gradient(to right, #d71a21 0%, #b31217  51%, #e52d27  100%);
    width: 100px;
    padding: 7px 20px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
    cursor:pointer;
  }

  .btn-grad-primary:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
 
  .customDatePickerWidth, 
  .customDatePickerWidth > div.react-datepicker-wrapper, 
  .customDatePickerWidth > div > div.react-datepicker__input-container
  .customDatePickerWidth > div > div.react-datepicker__input-container input {
     width: 100%;
  }

  .country__list,.scheme__list,.customer__list,.admin__login__card{
    border: none;
    border-radius: 10px;
    /* box-shadow: rgba(102, 0, 0, 0.1) 0px 2px 4px, rgba(102, 0, 0, 0.1) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
    box-shadow: 0px 5px 10px 1px rgba(102, 0, 0, 0.3);
  }