.table__head {
  background-color: rgb(163, 1, 1);
  color: white;
  font-weight: bold;
  margin: 20px 20px;

  border-radius: 6px;
  /* 
  > div {
    width: 100px;
  } */
}

.table__head > tr td {
  background-color: rgb(163, 1, 1);
  color: white;
  margin: 10px;
}

.table__head > tr td:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.table__head > tr td:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.table__data {
  margin: 20px 20px;
  border-radius: 6px;
  box-shadow: 0px 1px 5px 5px rgba(214, 214, 214,0.5);
  
  .btn {
    width: 100px;
  }
 
}



@media only screen and (min-width: 992px) {
  .mobile__view {
    display: none;
  }
}

@media only screen and (max-width: 567px) {
  .web__view {
    display: none;
  }
}


.gift__card__topup__card, .gift_card_registered, .payment__history,.gift__card__create, .admin__log__index{
  border: none;
  border-radius: 10px;
  /* box-shadow: rgba(102, 0, 0, 0.1) 0px 2px 4px, rgba(102, 0, 0, 0.1) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
  /* box-shadow: 0px 5px 5px 3px rgba(102, 0, 0, 0.1); */
  box-shadow: 0px 5px 10px 1px rgba(102, 0, 0, 0.3);

}