.payment {
  border: 1px solid #f2f2f2;
  height: 400px;
  border-radius: 20px;
  background: #fff;
}

.payment_header {
  background: rgba(255, 102, 0, 1);
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
}

.check {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #fff;
  text-align: center;
}

.check i {
  vertical-align: middle;
  line-height: 50px;
  font-size: 30px;
}

.content {
  text-align: center;
}

.content h1 {
  font-size: 25px;
  padding-top: 25px;
}

#success-cnt,
#success-mark,
#fail-mark,
#fail-cnt {
  display: none;
}

.response__cc__avenue {
  border: none;
  border-radius: 10px;
  box-shadow: rgba(102, 0, 0, 0.1) 0px 2px 4px,
    rgba(102, 0, 0, 0.1) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  box-shadow: 5px 5px 10px 3px rgba(102, 0, 0, 0.1);
}

  
  .svg-box {
    display:inline-block;
    position: relative;
    width:150px;
}

.green-stroke {
    stroke:#7CB342;
}

.red-stroke {
    stroke: #FF6245;
}

.yellow-stroke {
    stroke: #FFC107;
}


.circular circle.path {
    stroke-dasharray: 330;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    opacity: 0.4;
    animation: 0.7s draw-circle ease-out;
}

/*------- Checkmark ---------*/
.checkmark{
	stroke-width: 6.25;
    stroke-linecap: round;
	position:absolute;
    top: 56px;
    left: 49px;
    width: 52px;
    height: 40px;
}

.checkmark path {
    animation: 1s draw-check ease-out;
}

@keyframes draw-circle {
    0% {
        stroke-dasharray: 0,330;
        stroke-dashoffset: 0;
        opacity: 1;
    }

    80% {
        stroke-dasharray: 330,330;
        stroke-dashoffset: 0;
        opacity: 1;
    }

    100%{
        opacity: 0.4;
    }
}

@keyframes draw-check {
    0% {
        stroke-dasharray: 49,80;
        stroke-dashoffset: 48;
        opacity: 0;
    }

    50% {
        stroke-dasharray: 49,80;
        stroke-dashoffset: 48;
        opacity: 1;
    }

    100% {
        stroke-dasharray: 130,80;
        stroke-dashoffset: 48;
    }
}

/*---------- Cross ----------*/

.cross {
    stroke-width:6.25;
    stroke-linecap: round;
    position: absolute;
    top: 54px;
    left: 54px;
    width: 40px;
    height: 40px;
}

.cross .first-line {
    animation: 0.7s draw-first-line ease-out;
}

.cross .second-line {
    animation: 0.7s draw-second-line ease-out;
}

@keyframes draw-first-line {
    0% {
        stroke-dasharray: 0,56;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 0,56;
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dasharray: 56,330;
        stroke-dashoffset: 0;
    }
}

@keyframes draw-second-line {
    0% {
        stroke-dasharray: 0,55;
        stroke-dashoffset: 1;
    }

    50% {
        stroke-dasharray: 0,55;
        stroke-dashoffset: 1;
    }

    100% {
        stroke-dasharray: 55,0;
        stroke-dashoffset: 70;
    }
}

.alert-sign {
    stroke-width:6.25;
    stroke-linecap: round;
    position: absolute;
    top: 40px;
    left: 68px;
    width: 15px;
    height: 70px;
    animation: 0.5s alert-sign-bounce cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.alert-sign .dot {
    stroke:none;
    fill: #FFC107;
}

@keyframes alert-sign-bounce {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
    }
}